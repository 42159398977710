/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('#nav [href*=contact], .button[href*=contact], ul.option-nav a[href*=contact]').fancybox({type: 'iframe'});

        $('.fancybox').fancybox({
          maxWidth: '90%',
          maxHeight: '90%',
          title: 'Enlarge'
        });

        $(document).on('click','.fancybox-title span:contains(Enlarge)',function(){
          window.open($('.fancybox-image').attr('src'),'_blank');
        });

        $('#slider').slick({
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: true,
          arrows: false
        });

        $("#mobile-nav").mmenu({
          // options
        }, {
          // configuration
          offCanvas: {
            pageSelector: "#page"
          }
        });


        /*
        var $thumbnails = $('#thumbnails');
        var $next = $thumbnails.find('.next');
        var $prev = $thumbnails.find('.prev');

        function setButtonState(){
          var $visible = $thumbnails.find('img:visible');
          var $hidden = $thumbnails.find('img:hidden');

          if($visible.length > 9){
            $next.removeClass('disabled');
          }
          else{
            $next.addClass('disabled')
          }


          if($hidden.length > 0){
            $prev.removeClass('disabled');
          }
          else{
            $prev.addClass('disabled')
          }
        }

        setButtonState();

        $next.click(function(e){
          e.preventDefault();
          var $visible = $thumbnails.find('img:visible');
          if($visible.length > 9){
            $visible.first().hide();
          }
          setButtonState();

        });

        $prev.click(function(e){
          e.preventDefault();
          var $hidden = $thumbnails.find('img:hidden');
          $hidden.last().show();
          setButtonState();
        });

        */

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
